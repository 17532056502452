require('./bootstrap');

window.Jquery = require('jquery');

import Alpine from 'alpinejs';

window.Alpine = Alpine;

Alpine.start();

window.wowjs = require('wowjs');

import 'wowjs/dist/wow.min.js';
